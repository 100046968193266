var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"Reports"}},[_c('div',{staticClass:"content"},[_c('Portal',{attrs:{"to":"action-bar"}},[_c('ItemActions',{attrs:{"item":"Report","group-by":"","order":"","criteria":"","columns":_vm.columns,"filter-by":[],"active-view":"","hide-actions":[
          'toggle',
          'sort',
          'group',
          'filter',
          'activeView',
          'export',
        ],"simple-search-filter":true,"refresh":false},on:{"search":_vm.searchRow}})],1),_c('DetailsGridButton',{attrs:{"connect-apps":_vm.reportsType},on:{"click":_vm.routes}}),_c('router-view',{key:_vm.$route.fullPath})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }