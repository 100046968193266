<!-- ParentComponent.vue -->
<template>
  <div id="Reports">
    <div class="content">
      <Portal to="action-bar">
        <ItemActions
          item="Report"
          group-by=""
          order=""
          criteria=""
          :columns="columns"
          :filter-by="[]"
          active-view=""
          :hide-actions="[
            'toggle',
            'sort',
            'group',
            'filter',
            'activeView',
            'export',
          ]"
          :simple-search-filter="true"
          :refresh="false"
          @search="searchRow"
        />
      </Portal>
      <DetailsGridButton :connect-apps="reportsType" @click="routes">
      </DetailsGridButton>
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
import DetailsGridButton from "./components/DetailsGridButton.vue";
import { Portal } from "portal-vue";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";

export default {
  name: "RepositoriesReportsOverview",

  components: {
    DetailsGridButton,
    Portal,
    ItemActions,
  },

  props: {
    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      searchQuery: "",
      columns: [
        {
          id: this.$nano.id(),
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          route: "",
        },
        {
          id: this.$nano.id(),
          name: "description",
          label: "Description",
          type: "SUBTITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          route: "",
        },
      ],
      reportsType: [
        {
          name: "Activity Report",
          description:
            "The purpose of activity reports is to track and monitor the usage and performance of systems or processes, identify any anomalies or suspicious behavior, and ensure compliance with policies and regulations",
          icon: "mdi-file-table",
          label: "Get Report",
          color: "blue",
          route: "/reports/activity",
        },
        {
          name: "Data Capture",
          description:
            "A summary of data collection activities, detailing information such as sources, collection methods, and captured data types.",
          icon: "mdi-file-table-box-outline",
          label: "Get Report",
          color: "deep-orange",
          route: "/reports/data-capture",
        },
        {
          name: "Signature Report",
          description:
            "This report compiles a comprehensive list of signatures utilized within the system, encompassing both electronic (E-Sign) and digital signatures. It details the entities and timestamps associated with each signature.",
          icon: "mdi-draw",
          label: "Get Report",
          color: "orange",
          route: "/reports/signature",
        },
      ],
      reportsTypeData: [],
    };
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.tab = this.$route.name;
        const index = this.breadcrumbs.length - 1;
        this.breadcrumbs[index].label = this.$route.meta.breadcrumb;
      },
    },
  },

  created() {
    this.reportsTypeData = this.reportsType;
  },
  methods: {
    searchRow(search) {
      if (search) {
        this.reportsType = this.reportsTypeData.filter((row) => {
          for (let cell in row) {
            let index = this.columns.findIndex((column) => {
              return column.name === cell;
            });
            if (index >= 0 && row[cell]) {
              if (
                String(row[cell]).toLowerCase().includes(search.toLowerCase())
              ) {
                return row;
              }
            }
          }
        });
      } else {
        this.reportsType = this.reportsTypeData;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#Reports {
  .content {
    padding: 24px 0px;
    min-height: calc(100vh - 256px);
  }
}
</style>
