<template>
  <div id="Report-list">
    <div class="row items-start q-col-gutter-md">
      <template v-for="(row, index) in connectApps">
        <div
          v-if="checkTenant || row.label !== 'Coming Soon'"
          :key="index"
          class="col-xs-6 col-sm-4 col-md-3 col-lg-3"
        >
          <div class="card">
            <div class="row items-center q-pa-md">
              <div class="col-12">
                <div class="row items-center">
                  <div class="image col-auto q-mr-sm">
                    <q-avatar
                      :color="row.color"
                      text-color="white"
                      :icon="row.icon"
                      size="38px"
                    />
                  </div>
                  <div
                    class="col text-base font-bold text-uppercase items-center"
                  >
                    {{ row.name }}
                  </div>
                </div>
              </div>
              <div class="col-12 q-pa-sm">
                <div class="description q-mt-xs">
                  {{ row.description }}
                </div>
              </div>
              <div class="col-12">
                <BaseButton
                  :label="row.label"
                  icon="mdi-content-duplicate"
                  class="q-ml-md"
                  style="float: right"
                  @click="$router.push(row.route)"
                ></BaseButton>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "DetailsGridButton",

  components: {},

  props: {
    connectApps: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapState(["darkTheme"]),
  },

  watch: {
    $routes: {
      immediate: true,
      deep: true,
      handler() {
        this.tab = this.$route.name;
        const index = this.breadcrumbs.length - 1;
        this.breadcrumbs[index].label = this.$route.meta.breadcrumb;
      },
    },
  },
};
</script>

<style lang="scss">
#Report-list {
  .card {
    background-color: var(--component-bg-color);
    border-radius: 6px;
    border: 1px solid var(--component-bg-color);
  }
  .card:hover {
    -moz-box-shadow: 0 0 10px #c6c6c6;
    -webkit-box-shadow: 0 0 10px #c6c6c6;
    box-shadow: 0 0 10px #c6c6c6;
  }

  .description {
    color: var(--icon-color-inverted);
    margin-bottom: 4px;
    text-align: justify;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .description:hover {
    overflow: visible;
    white-space: normal;
    height: auto; /* just added this line */
  }
}
</style>
